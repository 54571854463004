import { h } from 'vue';
// import { useDark, useToggle } from "@vueuse/core";
//
// const isDark = useDark()
// const toggleDark = useToggle(isDark)

export default {

    components: {  },
    data() {
        return {
            // menuOptions: [
            //     {
            //         label: "Math",
            //         key: "math",
            //         children: [
            //             {
            //                 label: () => h('a',{ href: '/', }, 'Simple calculator'),
            //                 key: "/",
            //             },
            //             {
            //                 label: () => h('a',{ href: '/scientific-calculator', }, 'Scientific calculator'),
            //                 key: "/scientific-calculator",
            //             },
            //             {
            //                 label: () => h('a',{ href: '/percentage', }, 'Percentage calculator'),
            //                 key: "/percentage",
            //             },
            //             {
            //                 label: () => h('a',{ href: '/fractions', }, 'Fractions calculator'),
            //                 key: "/fractions",
            //             }
            //         ]
            //     },
            // ],
            // selectedMenuItem: location.pathname.replace(location.search, ''),
            menuOpened: false,
            // searchQuery: ''
        }
    },
    computed: {
        // theme() {
        //     return isDark.value ? darkTheme : null;
        // },

    },

    methods: {
        toggleMenu() {
            this.menuOpened = !this.menuOpened;
        },
        // switchTheme() {
        //     toggleDark();console.log(isDark.value);
        //     // this.theme = isDark.value ? darkTheme : null;
        // }
    }

}