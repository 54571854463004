<template>
    <div class="cropper-uploader">

        <slot></slot>

<!--        <Button label="Select image" @click="onUploadClicked" size="large">-->
<!--            <template #icon>-->
<!--                <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" height="24" class="p-button-icon p-button-icon-left">-->
<!--                    <path d="M13.034 14.006a1 1 0 0 1-2 .006l2-.006ZM9.719 6.724l1.289-1.297.026 8.585 2-.006-.027-8.586 1.297 1.29a1 1 0 1 0 1.41-1.42l-3.719-3.695L8.3 5.314l1.419 1.41Z" fill="currentColor"/>-->
<!--                    <path d="M8.3 5.314a1 1 0 1 0 1.419 1.41L8.3 5.314ZM4 12a2 2 0 0 1 2-2 1 1 0 1 0 0-2 4 4 0 0 0-4 4v6a4 4 0 0 0 4 4h11a5 5 0 0 0 5-5v-5a4 4 0 0 0-4-4 1 1 0 1 0 0 2 2 2 0 0 1 2 2v5a3 3 0 0 1-3 3H6a2 2 0 0 1-2-2v-6Z" fill="currentColor"/>-->
<!--                </svg>-->
<!--            </template>-->
<!--        </Button>-->

      <SplitButton label="Select image" @click="onUploadClicked" :model="uploadOptions" size="large" :menuButtonProps="{'aria-label': 'More upload options'}">
        <template #icon>
          <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" height="24" class="p-button-icon p-button-icon-left">
            <path d="M13.034 14.006a1 1 0 0 1-2 .006l2-.006ZM9.719 6.724l1.289-1.297.026 8.585 2-.006-.027-8.586 1.297 1.29a1 1 0 1 0 1.41-1.42l-3.719-3.695L8.3 5.314l1.419 1.41Z" fill="currentColor"/>
            <path d="M8.3 5.314a1 1 0 1 0 1.419 1.41L8.3 5.314ZM4 12a2 2 0 0 1 2-2 1 1 0 1 0 0-2 4 4 0 0 0-4 4v6a4 4 0 0 0 4 4h11a5 5 0 0 0 5-5v-5a4 4 0 0 0-4-4 1 1 0 1 0 0 2 2 2 0 0 1 2 2v5a3 3 0 0 1-3 3H6a2 2 0 0 1-2-2v-6Z" fill="currentColor"/>
          </svg>
        </template>
      </SplitButton>

      <input type="file" @change="onFileSelected" ref="fileInput" accept="image/*">

      <slot name="bottom"></slot>

      <url-dialog v-model:visible="urlDialogVisible" @url-entered="onUrlEntered"></url-dialog>

    </div>
</template>

<script>
import SplitButton from 'primevue/splitbutton';
import { ref } from "vue";
import UrlDialog from "@/apps/cropper/UrlDialog";

export default {
  name: 'uploader',
  components: { UrlDialog, SplitButton },
  props: {
    modelValue: Object
  },
  setup() {
    const urlDialogVisible = ref(false);

    return {
        uploadOptions: [
            {
                label: 'Upload from URL',
                command: () => {
                  urlDialogVisible.value = true;
                }
            },
        ],
        urlDialogVisible,
    }
  },
  mounted() {
    this.$el.ondrop = this.onDropFile;
    this.$el.ondragover = this.onDragOverFile;
  },
  methods: {
    onUploadClicked() {
      this.$refs.fileInput.click();
    },
    onUrlEntered(url) {
      url = url.toString().trim();
      const urlMatch = url.match(/^http.*\/(.*)\.(jpg|jpeg|png|webp|avif)(\?.*?)?$/);
      if (!urlMatch) {
        alert('Error: invalid URL (');
        return;
      }
      this.urlDialogVisible = false;
      this.$emit('update:modelValue', {
        url: 'https://corsproxy.io/?' + url,
        name: urlMatch[1] + '.' + urlMatch[2],
        type: 'image/' + urlMatch[2],
      });
    },
    onDropFile(e) {
      e.preventDefault();
      console.log(e);
      if (e.dataTransfer.items) {
        if (e.dataTransfer.items[0].kind === "file") {
          this.readFile(e.dataTransfer.items[0].getAsFile());
        }
      } else if (e.dataTransfer.files) {
        this.readFile(e.dataTransfer.files[0]);
      }
    },
    onDragOverFile(e) {
      e.preventDefault();
    },
    onFileSelected(e) {
      this.readFile(e.target.files[0]);
    },
    readFile(file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.$emit('update:modelValue', {
          url: reader.result,
          name: file.name,
          type: file.type,
        });
      };
    }
  }
}
</script>

<style lang="scss">
.cropper-uploader {
  padding: 20px 10px 0;
  text-align: center;
  height: 100%;

  p {
    max-width: 400px;
    margin: 20px auto 24px;
  }

  input[type=file] {
    display: none;
  }
}
</style>
