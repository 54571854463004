<template>
  <div class="cropper">

    <div class="editor">
      <div class="cropperjs-wrapper">
        <img :src="image.url" ref="img" class="original-img" @error="onImageLoadError" crossorigin="anonymous">
      </div>
    </div>

    <div class="toolbox-panel">
      <div class="panel flex-panel scrollable-panel" :class="{ 'flex-panel-visible': toolboxVisible }">
        <toolbox v-model="cropData"
                 @update:model-value="updateCropData"
                 @update:ratio="updateAspectRatio"
                 :width="naturalWidth"
                 :height="naturalHeight"
        ></toolbox>
      </div>

      <div class="panel fixed-panel">

        <Button @click="$emit('reset')" severity="secondary" outlined>
          <template #icon>
            <svg viewBox="0 0 15 15" xmlns="http://www.w3.org/2000/svg" height="16"><path fill-rule="evenodd" clip-rule="evenodd" d="M4.854 2.146a.5.5 0 0 1 0 .708L3.707 4H9a4.5 4.5 0 1 1 0 9H5a.5.5 0 0 1 0-1h4a3.5 3.5 0 1 0 0-7H3.707l1.147 1.146a.5.5 0 1 1-.708.708l-2-2a.5.5 0 0 1 0-.708l2-2a.5.5 0 0 1 .708 0Z" fill="currentColor"/></svg>
          </template>
        </Button>

        <Button @click="toolboxVisible = !toolboxVisible" :severity="toolboxVisible ? '' : 'secondary'" :outlined="!toolboxVisible" class="visible-if-sm">
          <template #icon>
            <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" height="16" ><path fill-rule="evenodd" clip-rule="evenodd" d="M14.279 2.152C13.909 2 13.439 2 12.5 2s-1.409 0-1.779.152a2.008 2.008 0 0 0-1.09 1.083c-.094.223-.13.484-.145.863a1.615 1.615 0 0 1-.796 1.353 1.64 1.64 0 0 1-1.579.008c-.338-.178-.583-.276-.825-.308a2.026 2.026 0 0 0-1.49.396c-.318.242-.553.646-1.022 1.453-.47.807-.704 1.21-.757 1.605-.07.526.074 1.058.4 1.479.148.192.357.353.68.555.477.297.783.803.783 1.361 0 .558-.306 1.064-.782 1.36-.324.203-.533.364-.682.556-.325.421-.469.953-.399 1.479.053.394.287.798.757 1.605.47.807.704 1.21 1.022 1.453.424.323.96.465 1.49.396.242-.032.487-.13.825-.308a1.64 1.64 0 0 1 1.58.008c.486.28.774.795.795 1.353.015.38.051.64.145.863.204.49.596.88 1.09 1.083.37.152.84.152 1.779.152s1.409 0 1.779-.152a2.008 2.008 0 0 0 1.09-1.083c.094-.223.13-.483.145-.863.02-.558.309-1.074.796-1.353a1.64 1.64 0 0 1 1.579-.008c.338.178.583.276.825.308.53.07 1.066-.073 1.49-.396.318-.242.553-.646 1.022-1.453.47-.807.704-1.21.757-1.605a1.99 1.99 0 0 0-.4-1.479c-.148-.192-.357-.353-.68-.555-.477-.297-.783-.803-.783-1.361 0-.558.306-1.064.782-1.36.324-.203.533-.364.682-.556a1.99 1.99 0 0 0 .399-1.479c-.053-.394-.287-.798-.757-1.605-.47-.807-.704-1.21-1.022-1.453a2.026 2.026 0 0 0-1.49-.396c-.242.032-.487.13-.825.308a1.64 1.64 0 0 1-1.58-.008 1.615 1.615 0 0 1-.795-1.353c-.015-.38-.051-.64-.145-.863a2.007 2.007 0 0 0-1.09-1.083ZM12.5 15c1.67 0 3.023-1.343 3.023-3S14.169 9 12.5 9c-1.67 0-3.023 1.343-3.023 3s1.354 3 3.023 3Z" fill="currentColor"/></svg>
          </template>
        </Button>

        <Button label="Download" @click="download"></Button>

      </div>

    </div>

    <share-dialog v-model:visible="shareDialogVisible"></share-dialog>

  </div>
</template>

<script>
import Cropper from 'cropperjs';
import { ref } from "vue";
import Toolbox from "@/apps/cropper/Toolbox";
import Button from 'primevue/button';
import ToggleButton from 'primevue/togglebutton';
import ShareDialog from "@/apps/cropper/ShareDialog";

const shareViewedKey = 'share_view';

export default {
  name: 'cropper',
  components: { ShareDialog, Toolbox, Button, ToggleButton },
  props: {
    image: Object
  },
  setup() {
      return {
        cropper: null,
        cropData: ref({
          width: null,
          height: null,
          x: null,
          y: null,
        }),
        naturalWidth: ref(0),
        naturalHeight: ref(0),
        toolboxVisible: ref(false),
        shareDialogVisible: ref(false),
      }
  },
  mounted() {
    this.$refs.img.addEventListener('ready', () => {
      this.naturalWidth = this.$refs.img.naturalWidth;
      this.naturalHeight = this.$refs.img.naturalHeight;
    });

    this.cropper = new Cropper(this.$refs.img, {
      aspectRatio: NaN,
      viewMode: 1,
      responsive: true,
      zoomOnWheel: false,
      restore: false,
      checkOrientation: false,
      checkCrossOrigin: false,
      crop: (event) => {
        for (let k in event.detail) {
          this.cropData[k] = Math.round(event.detail[k])
        }
      },
    });
  },
  methods: {
    updateCropData(data) {
      this.cropper.setData(data);
    },
    updateAspectRatio(ratio) {
      this.cropper.setAspectRatio(ratio);
    },
    download() {
      let croppedImgCanvas = this.cropper.getCroppedCanvas({
        maxWidth: 4096,
        maxHeight: 4096,
        fillColor: "transparent",
        imageSmoothingEnabled: true,
        imageSmoothingQuality: "high"
      });

      const link = document.createElement("a");
      link.href = croppedImgCanvas.toDataURL(this.image.type);
      link.download = this.image.name.replace('.svg', '.png');
      link.click();

      this.showSharePopup();
    },

    showSharePopup() {

      if ('sessionStorage' in window) {
        if (sessionStorage.getItem(shareViewedKey)){
          return;
        };
      }

      setTimeout(() => {
        this.shareDialogVisible = true;

        if ('sessionStorage' in window) {
          sessionStorage.setItem(shareViewedKey, 1)
        }
      }, 1000)
    },

    onImageLoadError() {
      alert('Error while image loading (');
      this.$emit('reset');
    }
  }
}
</script>

<style lang="scss">
$mobile-breakpoint: 850px;

.cropper {

  height: 100%;
  overflow: hidden;

  @media (min-width: $mobile-breakpoint) {
    display: flex;
    flex-direction: row;
    align-items: stretch;
  }
  @media (max-width: $mobile-breakpoint) {
    flex-direction: column;
  }

  .editor {
    flex: 1;
    display: flex;
    //align-items: center;
    align-items: flex-start;
    justify-content: center;
    padding-top: 30px;

    .cropperjs-wrapper {
      //max-width: 800px;
      max-height: 100%;
      //overflow-y: auto;

      .original-img {
        display: block;
        max-width: 100%;
      }
    }

    @media (max-width: $mobile-breakpoint) {
      position:absolute;
      width: 100%;
      top: 0;
      left: 0;
      bottom: 78px;
      padding-top: 15px;
    }
  }

  .toolbox-panel {
    position: relative;
    flex-basis: 350px;
    background: #fff;

    border-left: 1px solid var(--surface-border);

    display: flex;
    flex-direction: column;

    .panel {
      padding: 16px;
      background: #fff;
    }

    .fixed-panel {
      display: flex;
      gap: 1rem;
      border-top: 1px solid var(--surface-border);
      z-index: 2;
    }

    .flex-panel {
      flex: 1;
      overflow: hidden;
    }

    .scrollable-panel {
      overflow-y: auto;
    }

    .p-inputwrapper {
      width: 100%;
    }

    @media (max-width: $mobile-breakpoint) {
      position: fixed;
      left: 0;
      bottom: 0;
      width: 100%;

      flex-basis: 78px;
      //box-shadow: 0 -3px 6px 0 rgba(50,50,50,.3);

      .flex-panel {
        //display: none;
        position: absolute;
        bottom: 100%;
        width: 100%;
        max-height: 40vh;
        border-top: 1px solid var(--surface-border);
        background: rgba(255,255,255,0.8);
        backdrop-filter: blur(8px);
        transform: translateY(100%);
        transition: transform 200ms ease;

        &.flex-panel-visible {
          transform: translateY(0);
          box-shadow: 0 -3px 6px 0 rgba(50,50,50,.3);
        }
      }

      //.fixed-panel {
      //  position: fixed;
      //  left: 0;
      //  bottom: 0;
      //  width: 100%
      //}
    }
  }

}

.visible-if-sm {
  display: none;

  @media (max-width: $mobile-breakpoint) {
    display: inline-flex;
  }
}

</style>
