<template>
  <Dialog modal header="Crop image from URL" :style="{ width: '40rem' }" :breakpoints="{ '575px': '90vw' }" position="top">
      <div class="p-inputgroup mt-3">
        <input-text type="url" v-model="url" autofocus @keyup.enter="$emit('url-entered', url)"></input-text>
        <Button label="Go" @click="$emit('url-entered', url)"></Button>
      </div>
  </Dialog>
</template>

<script>
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import { ref } from "vue";

export default {
  name: 'url-dialog',
  components: { Dialog, InputText, Button },
  setup() {
    return {
      url: ref('')
    }
  }
}
</script>