<template>
    <div class="toolbox">

        <label for="ratio" class="font-bold block mb-2">Aspect ratio</label>
        <Dropdown v-model="ratio" :options="ratios" option-label="name" option-value="ratio" @update:modelValue="updateAspectRatio"></Dropdown>

        <label class="font-bold block mb-2 mt-3">Align</label>
        <span class="p-buttonset">

          <Button severity="secondary" outlined aria-label="Center vertical" @click="alignCenterVertical">
            <template #icon>
              <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" height="20"><path d="M7.5 5c-.935 0-1.402 0-1.75.201a1.5 1.5 0 0 0-.549.549C5 6.098 5 6.565 5 7.5v3.75H2a.75.75 0 0 0 0 1.5h3v3.75c0 .935 0 1.402.201 1.75a1.5 1.5 0 0 0 .549.549C6.098 19 6.565 19 7.5 19s1.402 0 1.75-.201a1.5 1.5 0 0 0 .549-.549c.201-.348.201-.815.201-1.75v-3.75h4v1.75c0 .935 0 1.402.201 1.75a1.5 1.5 0 0 0 .549.549c.348.201.815.201 1.75.201s1.402 0 1.75-.201a1.5 1.5 0 0 0 .549-.549c.201-.348.201-.815.201-1.75v-1.75h3a.75.75 0 0 0 0-1.5h-3V9.5c0-.935 0-1.402-.201-1.75a1.5 1.5 0 0 0-.549-.549C17.902 7 17.435 7 16.5 7s-1.402 0-1.75.201a1.5 1.5 0 0 0-.549.549C14 8.098 14 8.565 14 9.5v1.75h-4V7.5c0-.935 0-1.402-.201-1.75a1.5 1.5 0 0 0-.549-.549C8.902 5 8.435 5 7.5 5Z" fill="currentColor"/></svg>
            </template>
          </Button>

          <Button severity="secondary" outlined aria-label="Center horizontal" @click="alignCenterHorizontal">
            <template #icon>
              <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" height="20"><path d="M19 7.5c0-.935 0-1.402-.201-1.75a1.5 1.5 0 0 0-.549-.549C17.902 5 17.435 5 16.5 5h-3.75V2a.75.75 0 0 0-1.5 0v3H7.5c-.935 0-1.402 0-1.75.201a1.5 1.5 0 0 0-.549.549C5 6.098 5 6.565 5 7.5s0 1.402.201 1.75a1.5 1.5 0 0 0 .549.549C6.098 10 6.565 10 7.5 10h3.75v4H9.5c-.935 0-1.402 0-1.75.201a1.5 1.5 0 0 0-.549.549C7 15.098 7 15.565 7 16.5s0 1.402.201 1.75a1.5 1.5 0 0 0 .549.549C8.098 19 8.565 19 9.5 19h1.75v3a.75.75 0 0 0 1.5 0v-3h1.75c.935 0 1.402 0 1.75-.201a1.5 1.5 0 0 0 .549-.549c.201-.348.201-.815.201-1.75s0-1.402-.201-1.75a1.5 1.5 0 0 0-.549-.549C15.902 14 15.435 14 14.5 14h-1.75v-4h3.75c.935 0 1.402 0 1.75-.201a1.5 1.5 0 0 0 .549-.549C19 8.902 19 8.435 19 7.5Z" fill="currentColor"/></svg>
            </template>
          </Button>

          <Button severity="secondary" outlined aria-label="Left" @click="alignLeft">
            <template #icon>
              <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" height="20"><path d="M7.375 7.875c0-.935 0-1.402.201-1.75a1.5 1.5 0 0 1 .549-.549c.348-.201.815-.201 1.75-.201h9c.935 0 1.402 0 1.75.201a1.5 1.5 0 0 1 .549.549c.201.348.201.815.201 1.75s0 1.402-.201 1.75a1.5 1.5 0 0 1-.549.549c-.348.201-.815.201-1.75.201h-9c-.935 0-1.402 0-1.75-.201a1.5 1.5 0 0 1-.549-.549c-.201-.348-.201-.815-.201-1.75ZM7.375 16.875c0-.935 0-1.402.201-1.75a1.5 1.5 0 0 1 .549-.549c.348-.201.815-.201 1.75-.201h6c.935 0 1.402 0 1.75.201a1.5 1.5 0 0 1 .549.549c.201.348.201.815.201 1.75s0 1.402-.201 1.75a1.5 1.5 0 0 1-.549.549c-.348.201-.815.201-1.75.201h-6c-.935 0-1.402 0-1.75-.201a1.5 1.5 0 0 1-.549-.549c-.201-.348-.201-.815-.201-1.75Z" fill="currentColor"/><path fill-rule="evenodd" clip-rule="evenodd" d="M3.375 23.125a.75.75 0 0 0 .75-.75v-20a.75.75 0 0 0-1.5 0v20c0 .414.336.75.75.75Z" fill="currentColor"/></svg>
            </template>
          </Button>

          <Button severity="secondary" outlined aria-label="Top" @click="alignTop">
            <template #icon>
              <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" height="20"><path d="M7.5 7.75c-.935 0-1.402 0-1.75.201a1.5 1.5 0 0 0-.549.549C5 8.848 5 9.315 5 10.25v9c0 .935 0 1.402.201 1.75a1.5 1.5 0 0 0 .549.549c.348.201.815.201 1.75.201s1.402 0 1.75-.201A1.5 1.5 0 0 0 9.799 21c.201-.348.201-.815.201-1.75v-9c0-.935 0-1.402-.201-1.75a1.5 1.5 0 0 0-.549-.549c-.348-.201-.815-.201-1.75-.201ZM16.5 7.75c-.935 0-1.402 0-1.75.201a1.5 1.5 0 0 0-.549.549C14 8.848 14 9.315 14 10.25v6c0 .935 0 1.402.201 1.75a1.5 1.5 0 0 0 .549.549c.348.201.815.201 1.75.201s1.402 0 1.75-.201a1.5 1.5 0 0 0 .549-.549c.201-.348.201-.815.201-1.75v-6c0-.935 0-1.402-.201-1.75a1.5 1.5 0 0 0-.549-.549c-.348-.201-.815-.201-1.75-.201Z" fill="currentColor"/><path fill-rule="evenodd" clip-rule="evenodd" d="M22.75 3.75a.75.75 0 0 1-.75.75H2A.75.75 0 1 1 2 3h20a.75.75 0 0 1 .75.75Z" fill="currentColor"/></svg>
            </template>
          </Button>

          <Button severity="secondary" outlined aria-label="Right" @click="alignRight">
            <template #icon>
              <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" height="20"><path d="M16.625 7.875c0-.935 0-1.402-.201-1.75a1.5 1.5 0 0 0-.549-.549c-.348-.201-.815-.201-1.75-.201h-9c-.935 0-1.402 0-1.75.201a1.5 1.5 0 0 0-.549.549c-.201.348-.201.815-.201 1.75s0 1.402.201 1.75a1.5 1.5 0 0 0 .549.549c.348.201.815.201 1.75.201h9c.935 0 1.402 0 1.75-.201a1.5 1.5 0 0 0 .549-.549c.201-.348.201-.815.201-1.75ZM16.625 16.875c0-.935 0-1.402-.201-1.75a1.5 1.5 0 0 0-.549-.549c-.348-.201-.815-.201-1.75-.201h-6c-.935 0-1.402 0-1.75.201a1.5 1.5 0 0 0-.549.549c-.201.348-.201.815-.201 1.75s0 1.402.201 1.75a1.5 1.5 0 0 0 .549.549c.348.201.815.201 1.75.201h6c.935 0 1.402 0 1.75-.201a1.5 1.5 0 0 0 .549-.549c.201-.348.201-.815.201-1.75Z" fill="currentColor"/><path fill-rule="evenodd" clip-rule="evenodd" d="M20.625 23.125a.75.75 0 0 1-.75-.75v-20a.75.75 0 0 1 1.5 0v20a.75.75 0 0 1-.75.75Z" fill="currentColor"/></svg>
            </template>
          </Button>

          <Button severity="secondary" outlined aria-label="Bottom" @click="alignBottom">
            <template #icon>
              <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" height="20"><path d="M7.5 17c-.935 0-1.402 0-1.75-.201a1.5 1.5 0 0 1-.549-.549C5 15.902 5 15.435 5 14.5v-9c0-.935 0-1.402.201-1.75a1.5 1.5 0 0 1 .549-.549C6.098 3 6.565 3 7.5 3s1.402 0 1.75.201a1.5 1.5 0 0 1 .549.549C10 4.098 10 4.565 10 5.5v9c0 .935 0 1.402-.201 1.75a1.5 1.5 0 0 1-.549.549C8.902 17 8.435 17 7.5 17ZM16.5 17c-.935 0-1.402 0-1.75-.201a1.5 1.5 0 0 1-.549-.549C14 15.902 14 15.435 14 14.5v-6c0-.935 0-1.402.201-1.75a1.5 1.5 0 0 1 .549-.549C15.098 6 15.565 6 16.5 6s1.402 0 1.75.201a1.5 1.5 0 0 1 .549.549C19 7.098 19 7.565 19 8.5v6c0 .935 0 1.402-.201 1.75a1.5 1.5 0 0 1-.549.549c-.348.201-.815.201-1.75.201Z" fill="currentColor"/><path fill-rule="evenodd" clip-rule="evenodd" d="M1.25 21a.75.75 0 0 1 .75-.75h20a.75.75 0 0 1 0 1.5H2a.75.75 0 0 1-.75-.75Z" fill="currentColor"/></svg>
            </template>
          </Button>

        </span>

        <label for="width" class="font-bold block mb-2 mt-3">Width (px)</label>
        <InputNumber inputId="width" v-model="value.width" @update:modelValue="updateCropData"></InputNumber>

        <label for="height" class="font-bold block mb-2 mt-3">Height (px)</label>
        <InputNumber inputId="height" v-model="value.height" @update:modelValue="updateCropData"></InputNumber>

        <label for="x" class="font-bold block mb-2 mt-3">Offset X (px)</label>
        <InputNumber inputId="x" v-model="value.x" @update:modelValue="updateCropData" :min="0"></InputNumber>

        <label for="y" class="font-bold block mb-2 mt-3">Offset Y (px)</label>
        <InputNumber inputId="y" v-model="value.y" @update:modelValue="updateCropData" :min="0"></InputNumber>

    </div>
</template>

<script>
import { ref } from "vue";
import Dropdown from 'primevue/dropdown';
import InputNumber from 'primevue/inputnumber';
import Button from 'primevue/button';

export default {
  name: 'toolbox',
  components: { Dropdown, InputNumber, Button },
  props: {
    modelValue: Object,
    width: {
      type: Number,
      required: true,
    },
    height: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
      return {
        value: ref(props.modelValue),
        ratio: ref(NaN),
        ratios: [
          { name: 'Custom', ratio: NaN },
          { name: '1/1 (Square)', ratio: 1 },
          { name: '4/3 (Horizontal)', ratio: 4/3 },
          { name: '3/4 (Vertical)', ratio: 3/4 },
          { name: '16/9 (Wide horizontal)', ratio: 16/9 },
          { name: '9/16 (Wide vertical)', ratio: 9/16 },
          { name: '4/6 (Vertical)', ratio: 4/6 },
        ],
      }
  },

  methods: {
    updateCropData() {
      this.$emit('update:modelValue', this.value);
    },
    updateAspectRatio() {
      this.$emit('update:ratio', this.ratio);
    },
    alignCenterVertical() {
      this.value.y = (this.height - this.value.height)/2;
      this.updateCropData();
    },
    alignCenterHorizontal() {
      this.value.x = (this.width - this.value.width)/2;
      this.updateCropData();
    },
    alignLeft() {
      this.value.x = 0;
      this.updateCropData();
    },
    alignTop() {
      this.value.y = 0;
      this.updateCropData();
    },
    alignRight() {
      this.value.x = this.width - this.value.width;
      this.updateCropData();
    },
    alignBottom() {
      this.value.y = this.height - this.value.height;
      this.updateCropData();
    },
  }
}
</script>

<style lang="scss">
.toolbox {
  .p-buttonset {
    display: flex;
    justify-content: stretch;

    .p-button {
      flex: 1;
    }
  }
}
</style>
