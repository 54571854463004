import "./assets/app.scss";
// import './theme-toggle'

if (process.env.NODE_ENV === 'production') {

    // GetStat
    const gs = document.createElement('script');
    gs.src = 'https://s.getstat.net/cdn/client.js';
    gs.async = true;
    gs.id = 'getstat';
    gs.setAttribute('data-id', 104);
    gs.setAttribute('data-domain', 'cropme.net');
    document.head.appendChild(gs);

    // GTAG
    // const ga = document.createElement('script');
    // ga.src = 'https://www.googletagmanager.com/gtag/js?id=G-6YGXB6FBXB';
    // ga.async = true;
    // document.head.appendChild(ga);
    // window.dataLayer = window.dataLayer || [];
    // function gtag(){dataLayer.push(arguments);}
    // gtag('js', new Date());
    // gtag('config', 'G-6YGXB6FBXB');
}

if ('serviceWorker' in navigator) {
    window.addEventListener('load', async () => {
        navigator.serviceWorker.register('/sw.js');
    });
}