import '../../boot'
import './index.scss'
import { createApp, ref } from 'vue/dist/vue.esm-bundler'
import AppLayoutMixin from '../../app-layout-mixin'

import PrimeVue from 'primevue/config';

import Uploader from './Uploader';
import Cropper from './Cropper';

const app = createApp({
    components: { Uploader, Cropper },
    mixins: [ AppLayoutMixin ],
    setup() {
        return {
            image: ref(null),
        }
    },
});

app.use(PrimeVue);

app.mount('#app');
