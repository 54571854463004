<template>
  <Dialog modal header="Thank us" :style="{ width: '500px' }" :breakpoints="{ '575px': '90vw' }">

    You can help cropme.net by share link with your friends.

    <div class="share-buttons">
      <Button :style="{ backgroundColor: '#3b5998' }" @click="shareFacebook">Facebook</Button>
      <Button :style="{ backgroundColor: '#1d9bf0' }" @click="shareTwitter">Twitter</Button>
      <Button :style="{ backgroundColor: '#0a66c2' }" @click="shareLinkedin">LinkedIn</Button>
      <Button :style="{ backgroundColor: '#FF4500' }" @click="shareReddit">Reddit</Button>
    </div>

  </Dialog>
</template>

<script>
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';

// const shareUrl = encodeURIComponent('https://cropme.net?utm_source=share');

export default {
  name: 'share-dialog',
  components: { Dialog, Button },
  methods: {
    shareFacebook() {
      (window.open('https://www.facebook.com/sharer.php?u=https://cropme.net','popupwindow','scrollbars=yes,width=700,height=400')).focus();
      return false;
    },
    shareTwitter() {
      (window.open('https://twitter.com/intent/tweet?url=https://cropme.net&text=Crop your images online free!&hashtags=cropme','popupwindow','scrollbars=yes,width=700,height=500')).focus();
      return false;
    },
    shareLinkedin() {
      (window.open('http://www.linkedin.com/shareArticle?url=https://cropme.net','popupwindow','scrollbars=yes,width=700,height=630')).focus();
      return false;
    },
    shareReddit() {
      (window.open('https://reddit.com/submit?url=https://cropme.net&title=Crop your JPG, PNG or WEBP images online free with CropMe.net tool','popupwindow','scrollbars=yes,width=700,height=630')).focus();
      return false;
    },
  }
}
</script>

<style lang="scss">
.share-buttons {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
  margin-top: 24px;

  button {
    justify-content: center;
    border-color: transparent;
  }
}
</style>